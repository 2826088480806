.bold {
  font-weight: $font-bold;
}

.geco {
  color: $color-geco;
}

.white {
  color: $color-white;
  opacity: 1;
}

.gray {
  color: $color-white;
  opacity: 0.6;
}

.cyan {
  color: $color-cyan;
}

.blue {
  color: $color-blue;
}

.orange {
  color: $color-orange;
}

.green {
  color: $color-green;
}

.flex-container {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-space {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.grow-1 {
  flex-grow: 1;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.inactive {
  filter: brightness(0.5);
  pointer-events: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.font-small {
  font-size: $font-small;
}

.font-medium {
  font-size: $font-medium;
}

.font-large {
  font-size: $font-large;
}

.font-extra-large {
  font-size: $font-extra-large;
}

.font-bold {
  font-weight: $font-bold;
}
