.experience {
  &-header {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: $screen-tablet) {
      flex-wrap: wrap;
    }
  }

  &-progress,
  &-info {
    flex-basis: 50%;
    margin-bottom: 24px;
  }

  &-progress {
    @include flex-centered;
    flex-direction: column;

    .CircularProgressbar-text {
      font-size: 24px;
      font-weight: $font-bold;
      font-family: 'Oxanium';
    }

    &-container {
      width: 100px;
      height: 100px;
      filter: drop-shadow(2px 4px 6px $color-black);
    }

    @media (max-width: $screen-tablet) {
      flex-basis: 100%;
    }
  }

  &-info {
    @include flex-centered();
    flex-direction: column;

    &-item {
      @include flex-between();
      min-width: 200px;

      & + .experience-info-item {
        margin-top: 16px;
      }

      &-data {
        align-items: flex-start;
        margin-left: 20px;
        min-width: 150px;
      }
    }

    @media (max-width: $screen-tablet) {
      flex-basis: 100%;
    }
  }

  &-description {
    max-width: 580px;
    margin: 0 auto 16px;
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 32px;
  }

  &-loader {
    margin-top: 16px;
    text-align: center;
  }

  &-info-modal {
    color: $color-black;

    .link {
      font-size: $font-medium;
    }

    &-table {
      margin-bottom: 24px;

      &-header {
        font-weight: $font-bold;
        margin-bottom: 8px;
      }

      &-item {
        @include flex-between();
        flex-wrap: wrap;
        padding: 12px;
        color: $color-white;

        &:nth-child(2n) {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 5px;
        }
      }
    }
  }
}
