h1,
h2,
h3,
h4,
h5 {
  color: unset;
}

@media screen and (max-width: 850px) {
  .wallet-content-animated-move {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    margin-top: -10px;
  }

  .wallet-content-animated {
    right: unset !important;
  }
}

@media screen and (min-width: 850px) {
  .wallet-content {
    position: absolute;
    display: block;
    top: 25px;
    right: 10px;
    border-radius: 15px;
  }
}
.wallet-connect-button {
  position: absolute !important;
  top: 5px;
  right: 10px;
  z-index: 100;
  font-weight: bold;
}
.wallet-connect-addess {
  color: #fff;
}
/* .wallet-connect-addess {
  color: #fff;
} */
.wallet-balance-button {
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-left: 1em;
  padding-left: 1em;
}
@media screen and (min-width: 640px) {
  .wallet-balance-button::before {
    content: '|';
    position: absolute;
    left: 0;
  }
}

.wallet-section {
  margin-top: 30px;
  padding: 5px 15px;
}
.wallet-section-close {
  float: right;
}
.wallet-disconnect:hover {
  border: none;
}
.wallet-header {
  margin: 10px 0px;
  display: flex;
  font-size: 12px;
}
// @media screen and (min-width: 640px) {
//   .wallet-header {
//     margin: 10px 0px;
//     font-size: 11px;
//   }
// }
.wallet-balance-section {
  padding: 0 0 15px 0;
  border-top: 1px solid #40444a;
}
.wallet-balance-item {
  border-bottom: 1px solid #40444a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  font-size: 14px;
}

.wallet-button {
  width: 100%;
  text-transform: initial !important;
  font-size: 11px !important;
}

.wallet-empty {
  max-width: 150px;
}

.wallet-contect-options-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  /* color: #1c1121; */
}

.wallet-contect-options-title-second {
  color: #e4a101;
}

.wallet-contect-options-title-close {
  position: absolute !important;
  top: 5px;
  right: 15px;
}
.wallet-contect-options-content {
  display: flex;
  flex-direction: column-reverse;
  margin: 20px;
}

.wallet-content-animated {
  position: absolute;
  display: block;
  opacity: 0;
  top: 5px;
  right: -200px;
}

.wallet-content-animated-move {
  transition: all 0.5s;
  opacity: 1;
  position: absolute;
  display: block;
  top: 5px;
  right: 10px;
  border-radius: 15px;
}

.MuiDialog-root .MuiDialog-paper {
  border: none;
}
.MuiDialog-root * {
  color: #1c1121;
  border-radius: 5px !important;
}
.MuiDialog-root .MuiDialogContent-root button {
  background-color: #e4a101;
}
/* section.desktop {
border-radius: 5px !important;
}
section.desktop * {
color: #1c1121;
} */

header .terra-wallet-section {
  background-color: transparent;

  @media (max-width: 992px) {
    margin-right: 60px;
    .wallet-content {
      right: 75px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    margin: inherit;
  }

  * {
    color: #161417;
  }

  .wallet-connect-button {
    @media (min-width: 850px) {
      min-width: 200px;
    }
    @media (max-width: 850px) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      .MuiSvgIcon-root {
        font-size: 2.5rem;
      }
    }

    justify-content: flex-end;
    margin-right: 10px;
    position: relative !important;
    top: 0;
    left: 0;
    z-index: 0;
    border: none;
    // border: 2px solid #161417;
    // background-color: transparent !important;
    // background-color: #e4a101 !important;
    background-color: transparent !important;

    * {
      // color: #1c1121;
      color: #e4a101;
      text-transform: none;
    }

    &:hover {
      border: none;
      // border: 2px solid #1c1121;

      * {
        // color: #1c1121;
        color: #ffce59;
      }
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   height: 100%;
    //   width: 100%;
    //   transform: skewX(-16deg);
    //   z-index: -1;
    //   opacity: 1;
    //   background-image: none;
    //   bottom: 0;
    //   border-radius: 0;
    //   left: auto;
    //   background-color: #e4a101;
    // }

    .wallet-connect-addess {
      color: inherit;
    }

    &.MuiButton-containedPrimary {
      height: unset;
      box-shadow: none;
    }
  }

  .wallet-content {
    // border: 2px solid #1c1121;
    border: none;
    border-radius: 5px;
    top: 40px;

    .wallet-section {
      margin-top: 5px;

      .wallet-header {
        justify-content: flex-start;
      }

      .MuiButton-containedPrimary {
        background-color: #e4a101;
        // background-color: transparent;
        border-radius: 3px;
        height: unset;

        * {
          color: #1c1121;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
          transform: skewX(-16deg);
          z-index: -1;
          opacity: 1;
          background-image: none;
          bottom: 0;
          border-radius: 0;
          left: auto;
          background-color: #e4a101;
        }

        display: none;
      }

      .wallet-empty {
        text-align: center;
        padding-top: 20px;
      }
    }
    > .MuiButton-outlined {
      border-radius: 5px;
    }
  }
}
