$level-item-size: 140px;
$mobile-level-item-size: 130px;
$color-claimed: $color-blue-2;
$color-unclaimed: $color-orange;
$color-locked: #c4c4c4;

.level {
  &-items {
    @include flex-centered;
    flex-wrap: wrap;
    margin: -20px;

    @media (max-width: $screen-tablet) {
      padding: 0;
    }
  }

  &-item {
    @include level-orders();
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    font-size: $font-extra-small;
    width: $level-item-size;
    height: $level-item-size;
    margin: 32px 16px;
    padding: 8px;
    border-radius: $main-border-radius;
    background: $color-dark-2;

    @media (max-width: 340px) {
      width: $mobile-level-item-size;
      height: $mobile-level-item-size;
    }

    &-registration {
      &-text {
        text-align: center;
        width: 100%;
        margin-top: 10px;

        &-header {
          font-size: $font-extra-large;
          line-height: $font-extra-large;
          font-weight: $font-bold;
          font-family: 'Oxanium';
          text-transform: uppercase;
        }

        &-description {
          font-size: $font-extra-small;
        }
      }

      &-button {
        position: absolute;
        top: 75%;
        left: -10px;
        transform: translateY(-50%);
        width: calc(100% + 20px);
        background-color: $color-geco;
        color: $color-black;
        border-radius: 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-small;
        padding: 12px 0;
        font-weight: $font-bold;
        border: none;

        &[disabled] {
          opacity: 1;
          filter: grayscale(1);
        }

        &:hover:not([disabled]) {
          cursor: pointer;
          background-color: $color-geco-hover;
        }
      }
    }

    &:first-child,
    &:last-child {
      background: $color-claimed;
    }

    &.level {
      &-0 {
        user-select: initial;
      }

      &-1 {
        .level-item-chest {
          display: none;
        }
      }

      &-10,
      &-11,
      &-12,
      &-13,
      &-14,
      &-15 {
        .level-item-indicator {
          font-size: 32px;
          left: -25px;
        }
      }

      &-15 {
        .level-item-indicator,
        .level-item-header {
          text-shadow: 0 0 20px $color-white, 0 0 30px $color-geco-hover,
            0 0 40px $color-geco-hover, 0 0 50px $color-geco-hover,
            0 0 60px $color-geco-hover, 0 0 70px $color-geco-hover,
            0 0 80px $color-geco-hover;
        }
      }
    }

    &.claimed {
      @include link-config($color-claimed, solid);
      border: 1px solid $color-claimed;

      &.level {
        &-0 {
          .level-item-indicator,
          .level-item-indicator-cover,
          .level-item-header,
          .level-item-chest {
            display: none;
          }
        }
      }

      .level-item-indicator {
        color: $color-white;
      }

      .level-item-header {
        &-text {
          color: $color-white;
        }
      }

      .level-item-chest {
        filter: drop-shadow(3px 3px 3px $color-geco);
      }
    }

    &.unlocked {
      @include link-config($color-unclaimed, dashed);
      border: 1px solid $color-unclaimed;
      background: $color-dark-2;

      .level-item-indicator {
        color: $color-unclaimed;
      }

      .level-item-chest {
        filter: drop-shadow(3px 3px 3px $color-geco);
      }
    }

    &.locked {
      @include link-config($color-locked, dashed);
      border: 1px solid $color-locked;

      &:last-of-type {
        background: rgba(218, 248, 249, 0.2);

        .level-item-chest {
          filter: grayscale(1) drop-shadow(3px 3px 3px $color-geco);
        }

        .level-item-description {
          opacity: 0.8;
        }
      }

      .level-item-indicator {
        color: $color-locked;
      }

      .level-item-description {
        color: $color-white;
        opacity: 0.6;

        &-icon {
          filter: grayscale(1);
        }
      }

      .level-item-chest {
        filter: grayscale(1);
      }
    }

    &-indicator {
      @include flex-centered();
      font-size: 36px;
      color: $color-black;
      font-weight: $font-bold;
      position: absolute;
      top: -15px;
      left: -15px;
      width: 30px;
      height: 30px;
      z-index: 1;
      border-radius: 15px;

      &-cover {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 15px;
        height: 20px;
        border-bottom-right-radius: 20px;
        z-index: 0;
        background: $color-dark-2;
      }
    }

    &-header {
      position: relative;
      top: 7px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      &-text {
        color: $color-dark-2;
        line-height: 12px;
        position: absolute;
        top: 10px;
        width: 100%;
        font-size: $font-extra-small;
        text-align: center;
      }
    }

    &-chest {
      position: relative;
      left: 50%;
      bottom: -50%;
      transform: translate(-50%, -50%);
      height: 55px;
      width: fit-content;
      z-index: 1;
    }

    &-description {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 16px 8px 8px 8px;
      line-height: 1.25;
      text-align: center;
    }
  }
}
